import React from "react";
import { errorModalContext } from "../store/errorModalContext"
import catchErrorMsg from '../utility/commonCatchError';

class ErrorBoundary extends React.Component {
    static contextType = errorModalContext;
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    //static context to get the context

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.addEventListener('error', e => {

            // Get the error properties from the error event object
            const { message, filename, lineno, colno, error } = e;

            catchErrorMsg(error, { page: error?.stack?.split("at")[1] }, this.context);

        });
    }

    componentDidCatch(error, info) {

        catchErrorMsg(error, { page: info?.componentStack?.split("at")[1]?.split(" ")[1] }, this.context, "errorboundry");
    }

    render() {

        if (this.state.hasError) {
            // You can render any custom fallback UI
            return null;
        }

        return this.props.children;
    }
}

export default ErrorBoundary