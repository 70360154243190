import { getDashboardListAPI, getRiskProfileListAPI, getOrderBookListAPI, getTradeBookListAPI, getTradeBookDetailsListAPI, getInvestmentListAPI, getPortFolioDataDetailsAPI, getSubDomainExistAPI } from "../../apis/customers/DashboardAPI";

export default class BrokerService {

    async getDashboardList(subscriptionRequest) {
        return await getDashboardListAPI(subscriptionRequest);
    }

    async getRiskProfileList(riskProfileRequest) {
        return await getRiskProfileListAPI(riskProfileRequest);
    }

    async getOrderBookList(orderBookListRequest) {
        return await getOrderBookListAPI(orderBookListRequest);
    }

    async getTradeBookList(tradeBookRequest) {
        return await getTradeBookListAPI(tradeBookRequest);
    }

    async getTradeBookDetailsList(tradeBookOrderDetailsRequest) {
        return await getTradeBookDetailsListAPI(tradeBookOrderDetailsRequest);
    }

    async getInvestmentList(investmentRequest) {
        return await getInvestmentListAPI(investmentRequest);
    }

    async getPortFolioDataDetails(portfolioDataDetailsRequest) {
        return await getPortFolioDataDetailsAPI(portfolioDataDetailsRequest);
    }

    async getSubDomainExist(subDomain) {
        return await getSubDomainExistAPI(subDomain);
    }
}