const config = {
 apiRootUrl: process.env.REACT_APP_API_ROOT_URL ?? "",
 apiRootKeyTenant: process.env.REACT_APP_API_ROOT_KEY_TENANT ?? "",
 apiRootKeyAdmin: process.env.REACT_APP_API_ROOT_KEY_ADMIN ?? "",
 apiRootKeyInvestor: process.env.REACT_APP_API_ROOT_KEY_INVESTOR ?? "",
 apiSecret: process.env.REACT_APP_API_SECRET ?? "",
 serviceType: process.env.REACT_APP_SERVICE_TYPE ?? "",
 tenentCode: process.env.REACT_APP_TENENT_CODE ?? "",
 kfisUrl: process.env.REACT_APP_KFIS_URL ?? "",
 redirectUrl: process.env.REACT_APP_CLIENT_DEFAULT_URL ?? "",
 digilockerUrl: process.env.REACT_APP_DIGILOCKER_URL ?? "",
 digilockerAPIUrl: process.env.REACT_APP_DIGILOCKER_API_URL ?? "",
 digilockerKycUrl: process.env.REACT_APP_DIGILOCKER_KYC_URL ?? "",
 digilockerReferenceId: process.env.REACT_APP_DIGILOCKER_REFERENCE_ID ?? "",
 digilockerTenantId: process.env.REACT_APP_DIGILOCKER_TENANTID ?? "",
 digilockerAccessKey: process.env.REACT_APP_DIGILOCKER_ACCESS_KEY ?? "",
 payuUrl: process.env.REACT_APP_PAYU_URL ?? "",
 razorPayUrl: process.env.REACT_APP_RAZORPAY_URL ?? "",
 ipConfigUrl: process.env.REACT_APP_IP_CONFIG ?? "",
 esignLoadScriptJs: process.env.REACT_APP_LOAD_SCRIPT_JS ?? "",
 esignLoadScriptUrl: process.env.REACT_APP_LOAD_SCRIPT_URL ?? "",
 esignEnv: process.env.REACT_APP_ESIGN_ENV ?? "",
 popUp_Config: process.env.REACT_APP_PopUp_Config ?? "true",
};

export default config;
