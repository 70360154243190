
import React, { useState, createContext, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import errorParsing from "../utility/errorParsing";
import config from "../config";

// create context
export const errorModalContext = createContext();
// provider
export const ErrorModalContextProvider = ({ children }) => {

    const [popUpDetails, setPopDetails] = useState({
        state: false,
        message: "Some error occured. Do you want to submit? ",
    });

    const vertical = "bottom";
    const horizontal = "right";
    const handleClose = () => {
        setPopDetails((prev) => ({ ...prev, state: false }));
    };

    const action = (

        <React.Fragment>
            <div className="closeButtonErrorUtility">
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" className="closeIconErrorUtility" />
                </IconButton>
            </div>
            <IconButton
                size="small"
                className="Submit submitButtonErrorUtility"
                aria-label="close"
                color="inherit"
                sx={{ position: "relative", top: "3%", right: "3%", fontSize: "medium" }}
                onClick={async () => {
                    errorParsing()
                    setPopDetails((prev) => ({ ...prev, state: false }));
                }}
            >
                Submit
            </IconButton>
        </React.Fragment>
    );

    return (

        <errorModalContext.Provider value={{ popUpDetails, setPopDetails }}>
            <Snackbar
                ContentProps={{
                    sx: {
                        height: "100px",
                        minWidth: "300px",
                    },
                }}
                sx={{
                    height: "100px",
                    minWidth: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                onClose={() => setPopDetails((prev) => ({ ...prev, state: false }))}
                anchorOrigin={{ vertical, horizontal }}
                open={config.popUp_Config === "true" ? popUpDetails.state : null}
                key={"bottomright"}
                message={popUpDetails.message}
                action={action}
            />
            {children}
        </errorModalContext.Provider>
    );
};

// use context
export const useModalContext = () => {
    const user = useContext(errorModalContext);
    return user;
};